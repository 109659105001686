
import logoIcon from "../images/brandingPages/company_logo.svg"

export const ReducerFunc = (state,actionObj)=>{
    switch(actionObj.type){
        case "selectSidebar":{
            return { ...state, "sidebarSelected": actionObj.payload };
        }

        case "updateTheme":{
            return {...state,"theme":{...state.theme,...actionObj.payload}}
        }

        case "resetTheme":{
            return {...state,"theme":{...state.initialTheme}}
        }

        case "updateLogo":{
            return {...state,"logo":actionObj.payload}
        }

        case "resetLogo":{
            return {...state,"logo":logoIcon}
        }

        case "updateAppName":{
            return {...state,"appName":actionObj.payload}
        }

        default:{
            return state
        }
    }
}