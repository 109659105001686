import React, { useEffect, useState } from "react";
import { useColor } from "react-color-palette";
import useDispatchFunc from "../../hooks/useDispatchFunc";
import useStateValFunc from "../../hooks/useStateValuesFunc";
import { ColorService } from "../../util/ColorService";
import { isValidHexColor } from "../../util/ValidateHex";
import { ColorBox } from "./ColorBox";
import { HexCodeInput } from "./HexCodeInput";
import { PickerBox } from "./PickerBox";
import PickerInfo from "./PickerInfo";
import { PickerNote } from "./PickerNote";
import ResetBtn from "./ResetBtn";

export const ColorPicker = () => {
  const [{theme,initialTheme}] = useStateValFunc()
  const [dispatch] = useDispatchFunc()

  const [hexColor, setHexColor] = useState("#ffffff");
  const [color, setColor] = useColor("#ffffff"); 
  const [colorType, setColorType] = useState(0);

  const handleColorChange = (val) => {
    setHexColor(val.hex);
    setColor(val);

    let themeName = colorTypes[colorType].id
    dispatch({
      type:"updateTheme",
      payload:{[themeName] : val.hex}
    })

  };

  const handleHexColorChange = (ev) => {
    const hexValue = ev.target.value;
    const isHexCodeCorrect =
      hexValue.includes("#") && isValidHexColor(hexValue);

      setHexColor(hexValue);

    if (isHexCodeCorrect) {

      const convertedColor = ColorService.convert("hex", hexValue);
      setColor(convertedColor);

      let themeName = colorTypes[colorType].id
      dispatch({
        type:"updateTheme",
        payload:{[themeName] : hexValue}
      })
    }
  };

  const colorTypes = [
    { label: "Primary", id: "primaryColor",tooltipText:"Background Color" },
    { label: "Secondary", id: "secondaryColor",tooltipText:"MenuIcon & Button Color" },
    { label: "Tertiary", id: "tertiaryColor",tooltipText:"Focus Color" },
    // { label: "Gradient", id: "gradientColor" },
  ];

  const changeColorType = (value) => {
    setColorType(value);

    let selectedType = colorTypes[value].id
    const isHexCodeCorrect =
    theme[selectedType].includes("#") && isValidHexColor(theme[selectedType]);

    if (isHexCodeCorrect) {
      setHexColor(theme[selectedType]);

      const convertedColor = ColorService.convert("hex", theme[selectedType]);
      setColor(convertedColor);
    }
  };

  const resetColor = () => {
    const hexValue = initialTheme[colorTypes[colorType].id]
    const isHexCodeCorrect =
      hexValue.includes("#") && isValidHexColor(hexValue);
    setHexColor(hexValue);

    if (isHexCodeCorrect) {
      setHexColor(hexValue);
      
      const convertedColor = ColorService.convert("hex", hexValue);
      setColor(convertedColor);

      let themeValues = {...initialTheme}
      themeValues.textColor = theme.textColor  //preserving textColor b4 reseting other colors
      dispatch({
        type:"updateTheme",
        payload:themeValues
      })
    }
  };

  const ColorPickerInfo = {
    title: "Colors",
    desc: `The selected colours will be applied to the Whitelabel Template
    displayed on the screen. Your chosen colour scheme will enhance the
    appearance and branding of the app.`,
  };

  useEffect(()=>{
    const hexValue = theme[colorTypes[colorType].id];
    const isHexCodeCorrect =
      hexValue.includes("#") && isValidHexColor(hexValue);

      setHexColor(hexValue);

    if (isHexCodeCorrect) {

      const convertedColor = ColorService.convert("hex", hexValue);
      setColor(convertedColor);

      let themeName = colorTypes[colorType].id
      dispatch({
        type:"updateTheme",
        payload:{[themeName] : hexValue}
      })
    }
  },[])

  return (
    <>
      <div className="pickerWidgets">
        <PickerInfo info={ColorPickerInfo} />

        <ResetBtn resetFunc={resetColor} />

        {/*selected color picker */}
        <div className="color-picker-container">
          <div className="d-flex justify-content-between">
            {colorTypes.map((typeValue, index) => (
              <ColorBox
                label={typeValue.label}
                tooltipText={typeValue.tooltipText}
                index={index}
                ellipseColor={theme[typeValue.id]}
                isSelected={index === colorType}
                key={typeValue.id}
                changeColorType={changeColorType}
              />
            ))}
          </div>

          <div className="color-palette">
            <PickerBox color={color} handleColorChange={handleColorChange} height={180}/>
          </div>

          <HexCodeInput
            handleHexColorChange={handleHexColorChange}
            hexColor={hexColor}
          />
        </div>

        <PickerNote colorTypeSelected={colorType} />
      </div>
    </>
  );
};
