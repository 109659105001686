import React, { useState } from "react";
import { useColor } from "react-color-palette";
import useDispatchFunc from "../../hooks/useDispatchFunc";
import useStateValFunc from "../../hooks/useStateValuesFunc";
import { ColorService } from "../../util/ColorService";
import { isValidHexColor } from "../../util/ValidateHex";
import { ColorBox } from "./ColorBox";
import { HexCodeInput } from "./HexCodeInput";
import { PickerBox } from "./PickerBox";
import PickerInfo from "./PickerInfo";
import ResetBtn from "./ResetBtn";

export const TextColorPicker = () => {
  const [{theme,initialTheme}] = useStateValFunc()
  const [dispatch] = useDispatchFunc()

  const [hexColor, setHexColor] = useState(theme.textColor);
  const [color, setColor] = useColor(theme.textColor);
  const [colorType, setColorType] = useState(0);

  const handleColorChange = (val) => {
    setColor(val);
    setHexColor(val.hex);

    let themeName = colorTypes[colorType].id
    dispatch({
      type:"updateTheme",
      payload:{[themeName] : val.hex}
    })
  };

  const handleHexColorChange = (ev) => {
    const hexValue = ev.target.value;
    const isHexCodeCorrect =
      hexValue.includes("#") && isValidHexColor(hexValue);
    setHexColor(hexValue);

    if (isHexCodeCorrect) {     
      const convertedColor = ColorService.convert("hex", hexValue);
      setColor(convertedColor);

      let themeName = colorTypes[colorType].id
      dispatch({
        type:"updateTheme",
        payload:{[themeName] : hexValue}
      })
    }
  };

  const colorTypes = [{label:"Text Color",id:"textColor",tooltipText:"Font color"}];

  const changeColorType = (value) => {
    setColorType(value);
  };


  const resetColor = () => {
    const hexValue = initialTheme[colorTypes[colorType].id]
    const isHexCodeCorrect =
      hexValue.includes("#") && isValidHexColor(hexValue);
    setHexColor(hexValue);

    if (isHexCodeCorrect) {
      setHexColor(hexValue);
      
      const convertedColor = ColorService.convert("hex", hexValue);
      setColor(convertedColor);

      let themeName = colorTypes[colorType].id
      dispatch({
        type:"updateTheme",
        payload:{[themeName] : hexValue}
      })
    }
  };

  const TextColorPickerInfo = {
    title: "Font Style",
    desc: `Choose the text colour that will be applied to the Whitelabel Template
    displayed on the screen. Your selected text colour contributes to the
    overall design and readability of the white label, ensuring a harmonious
    and visually appealing presentation.`,
  };

  return (
    <>
      <div className="pickerWidgets">
        <PickerInfo info={TextColorPickerInfo} />

        <ResetBtn resetFunc={resetColor} />

        {/* text color picker */}
        <div className="text-color-picker">
          <div className="color-picker-title">Text Colour</div>

          <div className="d-flex justify-content-between">
            {colorTypes.map((typeValue, index) => (
              <ColorBox
                label={typeValue.label}
                tooltipText={typeValue.tooltipText}
                index={index}
                ellipseColor={hexColor}
                isSelected={index === colorType}
                key={typeValue.id}
                changeColorType={changeColorType}
              />
            ))}
          </div>

          <div className="color-palette">
            <PickerBox color={color} handleColorChange={handleColorChange} height={184}/>
          </div>

          <HexCodeInput
            handleHexColorChange={handleHexColorChange}
            hexColor={hexColor}
          />
        </div>

      </div>
    </>
  );
};
