import React from "react";

const PickerInfo = ({ info }) => {
  return (
    <>
      <h6 className="logoTitle">{info.title}</h6>

      <p className="logoDescription">
        {info.desc}
      </p>
    </>
  );
};

export default PickerInfo;
