import React from "react";
import { Preview } from "./Preview";
import { SelectionWidget } from "./SelectionWidget";

export const BrandingPage = ()=>{

    return(
        <>
        <SelectionWidget />
        <Preview />
        </>
    )
}