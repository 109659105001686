import React from "react";

export const HexCodeInput = ({handleHexColorChange,hexColor}) => {
  return (
    <>
      <div className="hexcode-display-container">
        <div className="hex-title">#Hex</div>
        <div className="hexcode-selector">
          <div
            className="hex-color-box"
            style={{ backgroundColor: hexColor }}
          ></div>
          {/* <div className="hex-text-box"># FFFFFF</div> */}
          <input
            type="text"
            className="form-control hex-text-box"
            // value="# FFFFFF"
            value={hexColor}
            onChange={(ev) => handleHexColorChange(ev)}
          />
        </div>
      </div>
    </>
  );
};
