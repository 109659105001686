import React from "react"
import { ColorPicker } from "../components/pickers/ColorPicker"
import { LogoPicker } from "../components/pickers/LogoPicker"
import { TextColorPicker } from "../components/pickers/TextColorPicker"
import useStateValFunc from "../hooks/useStateValuesFunc"

export const SelectionWidget = ()=>{
    const [{sidebarSelected}] = useStateValFunc()

    let sidebarComponent

    if(sidebarSelected === 0){
        sidebarComponent = <LogoPicker/>
    }else if(sidebarSelected === 1){
        sidebarComponent = <ColorPicker/>
    }else if(sidebarSelected === 2){
        sidebarComponent = <TextColorPicker/>
    }else{
        sidebarComponent = <LogoPicker/>
    }
    
    return(
        <>
        <div className="selection-widget">
        {sidebarComponent}
        </div>
        </>
    )
}