import React, { useRef } from "react"
import useDispatchFunc from "../../hooks/useDispatchFunc";

import PickerInfo from "./PickerInfo";
import ResetBtn from "./ResetBtn";
import { UploadPreview } from "./UploadPreview";

export const LogoPicker = ()=>{
    const [dispatch] = useDispatchFunc()

    const uploadFile = useRef(null);

    const handleChange = e => {
        // console.log(e.target.files[0],"e.target.files[0]")
        if(e.target.files[0]){
            dispatch({
                type:"updateLogo",
                payload:e.target.files[0]
            })
        }
    }

    const changeLogo = () =>{
        uploadFile.current.click()
    }

    const resetLogo = ()=>{
        dispatch({ type:"resetLogo"})
    }

    const LogoUploadInfo = {
        title: "Logo",
        desc: `Here, by updating the branding logos, the changes will be
        automatically applied throughout the entire Whitelabel Template
        interface wherever these logos appear and maintain consistent
        branding by aligning with your design. Example: Splash Screen, Above
        the menu bar, etc.`,
      };


    return (
      <>
        <div className="pickerWidgets">

          <PickerInfo info={LogoUploadInfo} />

          <div>
            <ResetBtn resetFunc={resetLogo} />

            {/* upload img */}
            <div className="logo-upload">
                <div className="upload-title">Logo</div>
                <div className="upload-box d-flex">
                    <UploadPreview/>
                    
                    <div className="logo-changeBtn" onClick={changeLogo}> 
                        {/* Change button */}
                        <input type="file" className="file-input" ref={uploadFile} onChange={handleChange} accept=".png"/> {/*accept=".svg"*/}
                        <div className="logo-changeBtn-label">
                            Change
                        </div>
                    </div>
                </div>
                <div>
                    <p className="logo-upload-footer">File format: <span className="logo-upload-footer-dark">PNG</span> only with aspect Ratio: <span className="logo-upload-footer-dark">600x300</span></p>
                </div>
            </div>
          </div>
        </div>
      </>
    );
}