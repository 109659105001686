import React from "react";
import { ColorPicker } from "react-color-palette";

export const PickerBox = ({color,handleColorChange,height})=>{

    const handleChange = (val)=>{
        handleColorChange(val)
    }
    
    return(
        <>
        <div className="picker-box">
        <ColorPicker color={color} onChange={handleChange}  hideAlpha={1} hideInput={1} height={height} />
        </div>
        </>
    )
}