import React, { useEffect, useState } from "react";
import useStateValFunc from "../../hooks/useStateValuesFunc";

export const UploadPreview  = () =>{
    const [{logo}] = useStateValFunc()
    const [logoImg,setLogoImg] = useState(() => {
        if(typeof(logo) === "string"){
          return logo
        }else{
          return URL.createObjectURL(logo)
        }
      })
    
      useEffect(()=>{
          // console.log("logo updated")
          setLogoImg(() => {
            if (typeof logo === "string") {
              return logo;
            } else {
              return URL.createObjectURL(logo);
            }
          });
      },[logo])
      
    return (
      <>
        <div className="upload-preview d-flex justify-content-center align-items-center">
          <img src={logoImg} alt="logochange" style={{ width: "170px" }} /> {/*height:"106px" */}
        </div>
      </>
    );
}