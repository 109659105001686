import React, { useEffect, useState } from "react";

import { MenuBar } from "./MenuBar";
import { BannerArea } from "./Home/BannerArea";
import { Carousel } from "./Home/Carousel";
import useStateValFunc from "../hooks/useStateValuesFunc";

const Home = () => {
  const [{theme,logo}] = useStateValFunc()
  const [logoImg, setLogoImg] = useState(() => {
    if(typeof(logo) === "string"){
      return logo
    }else{
      return URL.createObjectURL(logo)
    }
  });

  useEffect(()=>{
      setLogoImg(() => {
        if (typeof logo === "string") {
          return logo;
        } else {
          return URL.createObjectURL(logo);
        }
      });
  },[logo])

  return (
    <>
      <div>
        <div className="home-section" style={{ display: "flex",background:theme.primaryColor }}>
          <MenuBar logoImg={logoImg} />

          <div className="home-container">
            <BannerArea/>

            <Carousel/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
