// import logoIcon from "../images/logoUpload.png"

// import logoIcon from "../images/brandingPages/channel_logo.svg"
import logoIcon from "../images/brandingPages/company_logo.svg"



const initialTheme = {
    textColor:"#ffffff",
    primaryColor:"#0C2744",
    secondaryColor:"#FFFFFF",
    tertiaryColor:"#FA4B14",//"#03AAD3",
    gradientColor:"#0C2744"
}

  export const InitialStateValues = {
    sidebarSelected:0,
    theme:initialTheme,
    initialTheme,
    logo:logoIcon,
    appName:""
}