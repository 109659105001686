import React from "react";
// import undoImg from "../../images/undo.png"
// import redoImg from "../../images/redo.png"
import useStateValFunc from "../../hooks/useStateValuesFunc";
import useDispatchFunc from "../../hooks/useDispatchFunc";
import { uploadFile, uploadImage } from "../../upload/FileUpload";


const PreviewNavBar = ()=>{
    const[{theme,logo,appName}] = useStateValFunc()
    const [dispatch] = useDispatchFunc()
    
    const getJsonOutput = async()=>{
      if (appName.length < 1) {
        alert("Please, Enter your app name");
        return;
      }

      const bucketUrl = `${process.env.REACT_APP_BUCKET_URL}`;

      let jsonObj = { ...theme };

      await uploadFile(jsonObj, appName);
      await uploadImage(logo, appName);

      let logoUrl;
      if (typeof logo === "string") {
        logoUrl = logo;
      } else {
        logoUrl = `${bucketUrl}/${appName}/logo.png`;
      }

      let output = {
        themeFileUrl: `${bucketUrl}/${appName}/branding.js`,
        logoUrl,
        appName,
      };
      let a = JSON.stringify(output, null, 2);

      const fileData = a;
      const blob = new Blob([fileData], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.download = "branding.json";
      link.href = url;
      link.click();
    }

    const handleAppNameChange = (ev)=>{
      let targetValue = ev.target.value
      dispatch({type:"updateAppName",payload:targetValue})
    }

    return (
      <>
        <div className="preview-navbar">
          <div className="preview-nav">
            <div className="preview-nav-item">
              <label className="nav-label">Device</label>
              <select
                className="form-select device-select selected-option"
                defaultValue="TV App"
                disabled
              >
                <option value="Living Room"> TV App </option>
                {/* <option value="TV App"> Living Room </option> */}
                {/* <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */}
              </select>
            </div>

            <div className="preview-nav-item">
              <label className="nav-label"> Platform </label>
              <select
                className="form-select device-select selected-option"
                defaultValue="Apple"
                disabled
              >
                {" "}
                {/*platform-select*/}
                <option value="STB">STB</option>
                {/* <option value="Apple">Apple</option> */}
                {/* <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option> */}
              </select>
            </div>

            <div className="preview-nav-item">
              <div className="line"></div>
            </div>

            <div className="preview-nav-item">
              <label className="nav-label"> App Name </label>
              <input
                    type="text"
                    className="form-control app-name"
                    id="basic-url"
                    aria-describedby="basic-addon3 basic-addon4"
                    placeholder="Enter Your App name"
                    onChange={(ev)=>handleAppNameChange(ev)}
                  />
            </div>

            {/* <div className="preview-nav-item prev-next">
                <div className="prev-nextItem" onClick={()=>console.log("undo")}>
                    <img src={undoImg} alt="prev"/>
                </div>
                <div className="prev-nextItem" onClick={()=>console.log("redo")}>
                    <img src={redoImg} alt="next"/>
                </div>
            </div> */}


          </div>

          {/* Get link btn */}
          <div className="preview-nav">
            <div className="link-btn" onClick={() => getJsonOutput()}>
              Get JSON
            </div>
          </div>
        </div>
      </>
    );
}
export default PreviewNavBar