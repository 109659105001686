import AWS from 'aws-sdk';

export const uploadImage = async (file,appName="trial") => {
    if(typeof file === "string"){
      return
    }
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    
    const params = {
      Bucket: S3_BUCKET,
      Key: `${appName}/logo.png`,
      Body: file,
      ContentType:file.type
    };

    let upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading logo" + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err,"status");
      
      alert("Logo uploaded successfully.");
    });
  };

  const convertColorCode = (hexColor,alpha) =>{
    // Parse the hex color to an integer
    const intValue = parseInt(hexColor.slice(1), 16);

    // Add the alpha channel (0xff) to the integer value
    const result = `0x${alpha}${intValue.toString(16).toLowerCase()}`;

    return result
  }


  export const uploadFile = async (file, appName = "trial") => {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_KEY,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    let jsonObj = {...file}
    // Define the content you want to write to the JavaScript file
    const fileContent = 
`export const branding = {
  fontColor: ${convertColorCode(jsonObj.textColor,"ff")},
  background: ${convertColorCode(jsonObj.primaryColor,"ff")},
  focusBorderColor: ${convertColorCode(jsonObj.tertiaryColor,"ff")},
  focusFillColor: ${convertColorCode(jsonObj.tertiaryColor,"60")},
  searchKeyColor: 0xff243d57,
  searchKeyFocusColor: ${convertColorCode(jsonObj.tertiaryColor,"60")},
}
`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `${appName}/branding.js`,
      Body: fileContent,
      ContentType: "text/plain", // Set Content-Type to JSON
      // ContentDisposition: 'inline',     // Set Content-Disposition to inline
    };

    let upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading themeFile" + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    await upload.then((err, data) => {
      console.log(err, "status");
      alert("Themes File uploaded successfully.");
    });
  };

// export const uploadFile = async (file,appName="trial") => {
  
//   const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
//   const REGION = process.env.REACT_APP_REGION;

//   AWS.config.update({
//     accessKeyId: process.env.REACT_APP_AWS_ID,
//     secretAccessKey: process.env.REACT_APP_AWS_KEY,
//   });
//   const s3 = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: REGION,
//   });

//   const params = {
//     Bucket: S3_BUCKET,
//     Key: `${appName}/branding.json`,
//     Body: file,
//     ContentType: 'application/json',  // Set Content-Type to JSON
//     // ContentDisposition: 'inline',     // Set Content-Disposition to inline
//   };

//   let upload = s3
//     .putObject(params)
//     .on("httpUploadProgress", (evt) => {
//       console.log(
//         "Uploading themeFile" + parseInt((evt.loaded * 100) / evt.total) + "%"
//       );
//     })
//     .promise();

//   await upload.then((err, data) => {
//     console.log(err,"err");
//     console.log(data,"data")
//     alert("Themes File uploaded successfully.");
//   });
// };
