import  { useReducer } from "react";
import { InitialStateValues } from "./InitialStateValues";
import { ReducerFunc } from "./ReducerFunc";

const useStore = () =>{
    const [state,dispatch] = useReducer(ReducerFunc,InitialStateValues)

    return [state,dispatch]
}

export default useStore