import React from "react";

export const SideBarItems = ({logo,label,selected,changeSidebarSelection})=>{

    const handleClick =()=>{
        changeSidebarSelection(label)
    }
    
    return(
        <>
        <button className="nav-link active" onClick={()=>handleClick()}>
        <div className={`sidebar-item ${selected && "sidebar-item-selected"} `} >
                <img src={logo} alt="Icon" className="icon" />
                <span className="text">{label}</span>
        </div>
        </button>
        </>
    )
}