import React from "react";

import pickLogo from "../images/pickLogo.png"
import pickColor from "../images/pickColor.png"
import pickTextStyle from "../images/pickTextStyle.png"
import { SideBarItems } from "./SideBarItems";

import useStateValuesFunc from "../hooks/useStateValuesFunc"
import useDispatchFunc from "../hooks/useDispatchFunc"


export const SideBar = ()=>{
    const sidebaritems = [
      { label: "Logo", iconImg: pickLogo },
      { label: "Colors", iconImg: pickColor },
      { label: "Font Style", iconImg: pickTextStyle },
    ];
    
    const [{sidebarSelected}] = useStateValuesFunc()
    const [dispatch] = useDispatchFunc()

    const changeSidebarSelection = (selectedLabel)=>{
        const index = sidebaritems.findIndex((item)=>item.label === selectedLabel)
       dispatch({type:"selectSidebar",payload:index})
    }

    return (
      <>
        <nav>
          <div className="sidebar">
            <div className="d-flex flex-column">
                {
                    sidebaritems.map((item,index)=>(
                        <SideBarItems label={item.label} logo={item.iconImg} key={index} selected={index===sidebarSelected?true:false} changeSidebarSelection={changeSidebarSelection}/>
                    ))
                }

              {/* <div className="sidebar-item">
                <img src={pickLogo} alt="Icon" className="icon" />
                <span className="text">Logo</span>
              </div>
              <div className="sidebar-item">
                <img src={pickLogo} alt="Icon" className="icon" />
                <span className="text">Logo</span>
              </div> */}
            </div>
          </div>
        </nav>
      </>
    );
}