import React from "react";

export const PickerNote = ({colorTypeSelected}) => {
  // const colorNamesArray = ["Primary","Secondary","Tertiary","Gradient"]
  const colorNamesArray = [
    `The primary colour is the foundational colour and
  serves as the background and base colour in the app's design. It
  establishes the core visual theme of the interface.`,
    `The Secondary colour is used to highlight elements
  such as Text, Icons, and Buttons. Its purpose is to draw attention to
  key actions and information, guiding the user's focus within the
  interface.`,
    `The Tertiary colour is used to indicate selected
  portions and spotlight them to users. It is used to show the Selected
  states, Hover States, Progress bars, etc.`,
  ];
  return (
    <>
      <p className="picker-note">
        <span>Note:</span> {colorNamesArray[colorTypeSelected]}
        
        {/* <span>Note:</span> The {colorNamesArray[colorTypeSelected]} colour is used to highlight elements
        such as Text, Icons, and Buttons. Its purpose is to draw attention to
        key actions and information, guiding the user's focus within the
        interface. */}
      </p>
    </>
  );
};
