import React from "react";
// import openTabArrow from "../../images/openTabArrow.png"

export const PreviewFooter = ({selectedPage,handleClick,pages}) => {
//   const [selectedPage, setSelectedPage] = useState(0);

//   const pages = ["Splash", "Login", "Home", "Content Detail"];

//   const handleClick = (indexValue) =>{
//     setSelectedPage(indexValue)
//   }

  return (
    <>
      {/* Preview footer here */}
      <div className="preview-footer">
        <div className="page-selector">
          {pages.map((value,index) => (
            <div
              className={`footer-selector ${
                selectedPage===index && "footer-page-selected"
              }`}
              key={value}
              onClick={()=>handleClick(index)}
            >
              {value}
            </div>
          ))}

          {/* <div className="footer-selector footer-page-selected">Splash</div>
                <div className="footer-selector footer-page-selected">Login</div>
                <div className="footer-selector footer-page-selected">Home</div>
                <div className="footer-selector footer-page-selected">Content Detail</div> */}
        </div>

        {/* <div className="page-highlight">

            </div> */}
      </div>
      {/* <div className="ellipse">
            <img src={openTabArrow} alt="chevron" className="chevron"/>
            </div> */}
    </>
  );
};

export default PreviewFooter;
