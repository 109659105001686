import React from "react";

const ResetBtn = ({resetFunc}) => {
  
  return (
    <>
      <div>
        <div className="d-flex justify-content-end">
          {/* reset button */}
          <div className="resetBtn" onClick={resetFunc}>
            <div className="resetBtn-label">Reset</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetBtn;
