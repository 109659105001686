import React, { useState } from "react"

import PreviewNavBar from "../components/preview/PreviewNavBar"
import PreviewTab from "../components/preview/PreviewTab"
import PreviewFooter from "../components/preview/PreviewFooter"

export const Preview = ()=>{
    const [selectedPage, setSelectedPage] = useState(0);

    const handleClick = (indexValue) =>{
        setSelectedPage(indexValue)
    }

    const pages = ["Splash", "Login", "Home", "Content Detail"];

    return(
        <>
        {/* Preview here */}
        <div className="d-flex flex-column">
        <PreviewNavBar/>

        <PreviewTab selectedPage={selectedPage}/>

        <PreviewFooter handleClick={handleClick} selectedPage={selectedPage} pages={pages}/>
        </div>

        </>
    )
}