import React from "react"
import Splash from "../../brandingPages/Splash"
import Detail from "../../brandingPages/Detail"
import Home from "../../brandingPages/Home"
import Login from "../../brandingPages/Login"


 const PreviewTab = ({selectedPage})=>{
    // let componentToRender

    // if(selectedPage === 0){
    //     componentToRender= <Splash/>
    // }else if(selectedPage === 1){
    //     componentToRender = <Login/>
    // }else if(selectedPage === 2){
    //     componentToRender = <Home/>
    // }else if(selectedPage === 3){
    //     componentToRender = <Detail/>
    // }else{
    //     componentToRender= <Splash/>
    // }

    return (
      <>
        {/* Preview pages here */}
        {/* <div className="preview-window">
            <div className="page-active">
                {componentToRender}
            </div>
        </div> */}

        <div className="preview-window">
          <div className="page-active">
            <div style={{display:selectedPage === 0 ? "block" : "none"}}>
              <Splash />
            </div>
            <div style={{display:selectedPage === 1 ? "block" : "none"}}>
              <Login />
            </div>
            <div style={{display:selectedPage === 2 ? "block" : "none"}}>
              <Home />
            </div>
            <div style={{display:selectedPage === 3 ? "block" : "none"}}>
              <Detail />
            </div>
          </div>
        </div>
      </>
    );
}
export default PreviewTab