import React from "react"

export const ColorBox = ({label,tooltipText,ellipseColor,isSelected,changeColorType,index})=>{

  const clickHandler = ()=>{
    changeColorType(index)
  }

  
    return (
      <>
        <div className={`color-box ${isSelected?"color-box-selected":""}`} onClick={()=>clickHandler()} title={tooltipText}>
          <div className="color-pick-btn">
            <div className="color-pick-ellipse" style={{backgroundColor:ellipseColor}}></div>
            <div className="color-pick-label">{label}</div>
          </div>
        </div>
      </>
    );
}